import "@aws-amplify/ui-react/styles.css";
import "@google/model-viewer/dist/model-viewer";
import { AccountCircle, Logout } from "@mui/icons-material";
import { AppBar, Box, Container, Divider, IconButton, ListItemIcon, Menu, MenuItem, Toolbar, Tooltip, Typography } from "@mui/material";
import { useAuthenticator } from "@aws-amplify/ui-react";
import React, { useEffect } from "react";
import logo from "../logo.svg";
import useComponentVisible from "../hooks/useComponentVisible";
import { useSearchParams } from "react-router-dom";

export default function TopBar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [variant, setVariant] = React.useState("CED");
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const { user, signOut } = useAuthenticator((context) => [context.user, context.signOut]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [ref, openMenu, setOpen] = useComponentVisible(false);

  useEffect(() => {
    let params = new URL(document.location);
    // console.log(params);
    params.host.includes("bvo") ? setVariant("BVO") : setVariant("CED");
  }, []);

  return (
    <AppBar elevation={0} color="primary" position="static">
      <Container maxWidth="md">
        <Toolbar disableGutters>
          <img style={{ height: "35px" }} src={logo} alt="logo" />
          <Box sx={{ height: "35px", margin: 2 }}>
            <Divider light orientation="vertical" />
          </Box>

          <Typography variant="subtitle1" component="div" sx={{ flexGrow: 1 }}>
            {variant} Portal
          </Typography>
          <Tooltip title={user?.attributes?.email}>
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={openMenu ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openMenu ? "true" : undefined}
            >
              <AccountCircle htmlColor="#fff" />
            </IconButton>
          </Tooltip>

          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            ref={ref}
            open={openMenu}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{
              horizontal: "right",
              vertical: "top",
            }}
            anchorOrigin={{
              horizontal: "right",
              vertical: "bottom",
            }}
          >
            <MenuItem
              onClick={() => {
                handleCloseMenu();
                signOut();
              }}
            >
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
