import "@aws-amplify/ui-react/styles.css";
import "@google/model-viewer/dist/model-viewer";
import "highlight.js";

import { StorageManager } from "@aws-amplify/ui-react-storage";
import { CheckCircle } from "@mui/icons-material";
import {
	Alert,
	AlertTitle,
	Button,
	Card,
	CardContent,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FilledInput,
	FormControl,
	InputLabel,
	Select,
	Stack,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	Typography,
	MenuItem,
	IconButton,
	Tooltip,
	Collapse,
} from "@mui/material";
import React, { useEffect } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useAuthenticator } from "@aws-amplify/ui-react";

import clubs from "../data/clubs";
import seasons from "../data/seasons";
import types from "../data/types";

function UploadDialog({ setOpen, authKey }) {
	const [activeStep, setActiveStep] = React.useState(0);

	const [fileUrl, setFileUrl] = React.useState("");

	const handleClose = () => {
		setOpen(false);
	};
	const [club, setClub] = React.useState("");
	const [season, setSeason] = React.useState("");
	const [type, setType] = React.useState("");
	const [infoOpen, setInfoOpen] = React.useState(true);
	// console.log({ user });

	useEffect(() => {
		// console.log(activeStep);
		if (clubs.filter((cl) => authKey.includes(cl.value)).length === 1) {
			setClub(clubs.filter((cl) => authKey.includes(cl.value))[0]);
			if (activeStep === 0) {
				setActiveStep(1);
			}
			// setActiveStep((prevActiveStep) => prevActiveStep + 1);
		}
	}, []);

	function onClubChange(event) {
		if (!club.name) {
			setClub(event.target.value);
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		}
	}

	function onSeasonChange(event) {
		// console.log(event);
		setSeason(event.target.value);
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	}

	function onTypeChange(event) {
		// console.log(event);
		setType(event.target.value);
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	}
	// console.log("test");
	// console.log(clubs.filter((cl) => authKey.includes(cl.value)));
	// console.log(clubs.filter((cl) => authKey.includes(cl.value)).length);
	// console.log(clubs.filter((cl) => authKey.includes(cl.value))[0]);
	// console.log(activeStep);
	return (
		<Dialog open={true} onClose={handleClose}>
			<DialogTitle>
				Upload bestand
				<IconButton
					edge="start"
					color="inherit"
					onClick={() => {
						infoOpen ? setInfoOpen(false) : setInfoOpen(true);
					}}
					aria-label="close"
					style={{ position: "absolute", right: "8px", top: "8px" }}
				>
					<Tooltip title="Upload informatie" arrow>
						<InfoOutlinedIcon />
					</Tooltip>
				</IconButton>
			</DialogTitle>

			<DialogContent
				sx={{
					minWidth: "500px",
				}}
			>
				<Collapse in={infoOpen}>
					<Alert severity="info">
						<AlertTitle>Upload informatie</AlertTitle>
						<List style={{ position: "relative", right: "8px", top: "8px" }}>
							<ListItem>
								<ListItemText primary="1. Kies een voetbalclub, een specifiek seizoen en het gewenste type document." />
							</ListItem>
							<ListItem>
								<ListItemText primary="2. Upload een CSV-bestand met de juiste kolommen. Als je een voorbeeldbestand nodig hebt, kun je hier klikken voor een demo-bestand." />
							</ListItem>
							{/* <ListItem> */}
							{/* <ListItemText primary="Type" /> */}
							{/* </ListItem> */}
						</List>
						{/* 1. Kies een club, seizoen en type bestand. 
            2. Upload een csv bestand met de juiste kolommen. */}
					</Alert>
				</Collapse>
				<Stepper activeStep={activeStep} orientation="vertical">
					<Step>
						<StepLabel optional={club?.name}>Kies een club</StepLabel>
						<StepContent>
							<FormControl sx={{ width: "100%" }}>
								<InputLabel id="club-select-label">Club</InputLabel>
								<Select
									labelId="club-select-label"
									label="Club"
									value={club}
									onChange={onClubChange}
									sx={{
										width: "100%",
									}}
								>
									{/* {clubs.map((club) => {
                    return (
                      <MenuItem value={club} key={club?.name}>
                        {club?.name}
                      </MenuItem>
                    );
                  })} */}
									{authKey === "data/"
										? clubs.map((club) => {
												return (
													<MenuItem value={club} key={club?.name}>
														{club?.name}
													</MenuItem>
												);
										  })
										: clubs
												.filter((cl) => authKey.includes(cl.value))
												.map((club) => {
													return (
														<MenuItem value={club} key={club?.name}>
															{club?.name}
														</MenuItem>
													);
												})}
								</Select>
							</FormControl>
						</StepContent>
					</Step>
					<Step>
						<StepLabel optional={season}>Kies een seizoen</StepLabel>
						<StepContent>
							<FormControl sx={{ width: "100%" }}>
								<InputLabel id="season-select-label">Seizoen</InputLabel>
								<Select
									labelId="season-select-label"
									label="Season"
									value={season}
									onChange={onSeasonChange}
									sx={{
										width: "100%",
									}}
								>
									{seasons.map((season) => {
										return (
											<MenuItem value={season} key={season}>
												{season}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</StepContent>
					</Step>
					<Step>
						<StepLabel optional={type}>Kies een type</StepLabel>
						<StepContent>
							<FormControl sx={{ width: "100%" }}>
								<InputLabel id="type-select-label">Type</InputLabel>
								<Select
									labelId="type-select-label"
									label="Type"
									value={type}
									onChange={onTypeChange}
									sx={{
										width: "100%",
									}}
								>
									{types.map((type) => {
										return (
											<MenuItem value={type} key={type}>
												{type}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</StepContent>
					</Step>
					<Step>
						<StepLabel optional={"csv"}>Kies een bestand</StepLabel>
						<StepContent>
							<StorageManager
								acceptedFileTypes={[".csv"]}
								accessLevel="public"
								path={`data/${club.value}/${season}/`}
								maxFileCount={5}
								isResumable
								processFile={(params) => {
									params.key.replace(/[^a-zA-Z0-9!-_.*'()]/gm, "_");
									let array = params.key.split("/");
									array.pop();
									array[array.length] = `${type} - ${params.key.split("/").at(-1)}`;
									let newKey = array.join("/");
									newKey = newKey.replace(/[^a-zA-Z0-9!-_.*'()]/gm, "_");
									return {
										...params,
										key: newKey,
										metadata: {
											season: season,
										},
									};
								}}
								onUploadSuccess={async (data) => {
									setActiveStep((prevActiveStep) => prevActiveStep + 1);
									setFileUrl(`https://ced-portal-bucket150901-prod.s3.eu-west-1.amazonaws.com/public/${data.key}`);
								}}
							/>
							<Alert sx={{ mt: 4 }} severity="warning">
								<AlertTitle>Let op!</AlertTitle>
								Op dit moment zijn alleen .csv bestanden ondersteund.
								<br />
							</Alert>
						</StepContent>
					</Step>
				</Stepper>
				{activeStep === 4 && (
					<>
						<Card variant="outlined" sx={{ mt: 4, width: "500px" }}>
							<CardContent>
								<Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
									<CheckCircle color="success" fontSize="large" />
									<Typography>Bestand succesvol geupload!</Typography>
									<img alt="" style={{ width: "150px" }} />
									<FormControl variant="filled" size="small" fullWidth>
										<InputLabel>Bestand URL</InputLabel>
										<FilledInput type={"text"} value={fileUrl} label="Bestand URL" readOnly />
									</FormControl>
								</Stack>
							</CardContent>
						</Card>
					</>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Sluit venster</Button>
			</DialogActions>
		</Dialog>
	);
}

export default UploadDialog;
