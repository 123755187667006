import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import "@google/model-viewer/dist/model-viewer";
import { Add, Search, ThreeDRotationTwoTone } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  InputBase,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Paper,
  Typography,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Amplify, Storage } from "aws-amplify";
import FuzzySearch from "fuzzy-search";
import React, { useEffect } from "react";
import clubs from "./data/clubs";
import seasons from "./data/seasons";

import PreviewDialog from "./components/PreviewDialog";
import UploadDialog from "./components/UploadDialog";
import awsExports from "./aws-exports";
import TopBar from "./components/TopBar";

Amplify.configure(awsExports);

function App() {
  const [files, setFiles] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const [searchValue, setSearchValue] = React.useState("");

  const [previewKey, setPreviewKey] = React.useState(null);

  const [authKey, setAuthKey] = React.useState("");
  const { user } = useAuthenticator((context) => [context.user]);
  // console.log(user);

  useEffect(() => {
    // console.log(user);
    if (user && user.getSignInUserSession()) {
      // console.log(user.getSignInUserSession());
      const test = user.getSignInUserSession().accessToken.payload;
      // console.log({ test });

      const clubname = camelToUnderscore(test["cognito:groups"][0]);

      if (!clubname.includes("admin")) {
        // console.log(clubname);
        setAuthKey(`data/${clubname}/`);
      } else {
        setAuthKey(`data/`);
      }
    }
  }, [user]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  function camelToUnderscore(key) {
    var result = key.replace(/([A-Z])/g, " $1");
    return result.split(" ").join("_").toLowerCase();
  }

  async function fetchFiles() {
    // console.log({ authKey });
    Storage.list(authKey, { level: "public" })
      .then(({ results }) => {
        // console.log({ results });
        setFiles(results.filter((f) => f.key.slice(-1) !== "/"));
        // console.log(results);
      })
      .catch((err) => console.log(err));
  }

  async function deleteFile(key) {
    await Storage.remove(key, { level: "public" });
  }

  React.useEffect(() => {
    if (authKey) {
      fetchFiles();
    }
  }, [authKey]);

  const [filterClub, setFilterClub] = React.useState("");
  const [filterSeason, setFilterSeason] = React.useState("");

  function onClubChange(event) {
    setFilterClub(event.target.value);
  }

  function onSeasonChange(event) {
    setFilterSeason(event.target.value);
  }

  const vacancySearcher = new FuzzySearch(files, ["key"], {
    caseSensitive: false,
    sort: true,
  });

  const result = vacancySearcher.search(searchValue).filter((file) => {
    let foundSeason = false;
    let foundClub = false;
    foundClub = filterClub ? file.key.split("/")[1] === filterClub.value : true;
    foundSeason = filterSeason ? file.key.split("/")[2] === filterSeason : true;

    return foundClub ? foundSeason : foundClub;
  });

  return (
    <Box
      sx={{
        height: "100vh",
      }}
    >
      <Box
        sx={{
          transform: user ? "" : "translateY(calc(50vh - 60%))",
        }}
      >
        <Authenticator hideSignUp={true}></Authenticator>
        {user && (
          <>
            {open && (
              <UploadDialog
                setOpen={(op) => {
                  fetchFiles();
                  setOpen(op);
                }}
                authKey={authKey}
              />
            )}

            {previewKey && (
              <PreviewDialog
                fileNameKey={previewKey}
                setOpen={(op) => {
                  fetchFiles();

                  if (op === false) {
                    setPreviewKey(null);
                  }
                }}
                deleteFile={deleteFile}
                setFiles={setFiles}
              />
            )}

            <TopBar />
            <Container maxWidth="md" sx={{ mt: 4 }}>
              <Box
                sx={{
                  my: 2,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <Paper
                    variant="outlined"
                    sx={{
                      p: "2px 4px",
                      display: "flex",
                      alignItems: "center",
                      width: 400,
                    }}
                  >
                    <Search color="disabled" />
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      size="small"
                      placeholder="Filteren.."
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                  </Paper>
                  <FormControl sx={{ width: "100px", marginLeft: "1em" }}>
                    <InputLabel id="club-select-label" size="small">
                      Club
                    </InputLabel>
                    <Select
                      labelId="club-select-label"
                      label="Club"
                      size="small"
                      value={filterClub}
                      onChange={onClubChange}
                      sx={{
                        width: "100%",
                      }}
                    >
                      <MenuItem aria-label="None" value={undefined} key={"none"}>
                        None
                      </MenuItem>
                      {authKey === "data/"
                        ? clubs.map((club) => {
                            return (
                              <MenuItem value={club} key={club?.name}>
                                {club?.name}
                              </MenuItem>
                            );
                          })
                        : clubs
                            .filter((cl) => authKey.includes(cl.value))
                            .map((club) => {
                              return (
                                <MenuItem value={club} key={club?.name}>
                                  {club?.name}
                                </MenuItem>
                              );
                            })}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ width: "100px", marginLeft: "1em" }}>
                    <InputLabel id="club-select-label" size="small">
                      Season
                    </InputLabel>
                    <Select
                      labelId="season-select-label"
                      label="Season"
                      size="small"
                      value={filterSeason}
                      onChange={onSeasonChange}
                      sx={{
                        width: "100%",
                      }}
                    >
                      <MenuItem aria-label="None" value={undefined} key={"none"}>
                        None
                      </MenuItem>
                      {seasons.map((season) => {
                        return (
                          <MenuItem value={season} key={season}>
                            {season}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
                <Box>
                  <Button startIcon={<Add />} color="secondary" variant="contained" onClick={handleClickOpen}>
                    Upload
                  </Button>
                </Box>
              </Box>

              <Card variant="outlined">
                {files.length === 0 && (
                  <>
                    <Box alignContent="center" display="flex" flexDirection="column" height="100vh" justifyContent="center" width="100%">
                      <Box>
                        <Box display="flex" justifyContent="center">
                          <ThreeDRotationTwoTone color="disabled" fontSize="large" />
                        </Box>
                        <Typography sx={{ mt: 2 }} align="center" color="textPrimary" variant="subtitle1">
                          Geen bestanden gevonden
                        </Typography>
                        <Typography align="center" color="textSecondary" display="block" gutterBottom variant="caption">
                          Klik op de upload knop om een bestand te uploaden
                        </Typography>
                      </Box>
                    </Box>
                  </>
                )}
                {files.length > 0 && (
                  <CardContent>
                    <List>
                      {result.map((file) => {
                        const clubName = file.key.split("/")[1];
                        let fileName = file.key.split("/")[file.key.split("/").length - 1];
                        const club = clubs.find((club) => {
                          return club.value === clubName;
                        });
                        // fileName = fileName.split("/")[1];

                        return (
                          <ListItemButton
                            onClick={() => {
                              setPreviewKey(file.key);
                            }}
                            key={file.key}
                          >
                            <ListItemAvatar>
                              <Avatar sx={{ width: 50, height: 50 }} variant="square" src="csv.png" />
                            </ListItemAvatar>
                            <ListItemText primary={fileName.replaceAll("_", " ")} secondary="csv bestand" />
                            <Chip
                              avatar={<Avatar alt={clubName} src={`${clubName}.png`} />}
                              label={club?.name}
                              variant="outlined"
                              sx={{
                                color: club?.color,
                                borderColor: club?.color,
                              }}
                            />
                          </ListItemButton>
                        );
                      })}
                    </List>
                  </CardContent>
                )}
              </Card>
            </Container>
          </>
        )}
      </Box>
    </Box>
  );
}
export default App;
