const clubs = [
	{
		name: "Almere City",
		value: "almere_city",
		color: "#E3001B",
	},
	{
		name: "RB Haarlem",
		value: "rb_haarlem",
		color: "blue",
	},
	{
		name: "FC Dordrecht",
		value: "dordrecht",
		color: "#C32127",
	},
	{
		name: "Telstar",
		value: "telstar",
		color: "#0076C0",
	},
	{
		name: "FC Eindhoven",
		value: "fc_eindhoven",
		color: "#0056A4",
	},
	{
		name: "Helmond Sport",
		value: "helmond_sport",
		color: "black",
	},
	{
		name: "De Graafschap",
		value: "de_graafschap",
		color: "#0083C6",
	},
	{
		name: "MVV Maastricht",
		value: "mvv",
		color: "#E90928",
	},
	{
		name: "Ado Den Haag",
		value: "ado_den_haag",
		color: "#006D28",
	},
	{
		name: "Roda JC",
		value: "roda_jc",
		color: "#fcd600",
	},
	{
		name: "Willem II",
		value: "willem_ii",
		color: "#242c51",
	},
	{
		name: "FC Emmen",
		value: "fc_emmen",
		color: "#e43b3b",
	},
	{
		name: "VVV Venlo",
		value: "vvv_venlo",
		color: "#fee000",
	},
	{
		name: "NAC Breda",
		value: "nac_breda",
		color: "#f7db00",
	},
	{
		name: "FC Groningen",
		value: "fc_groningen",
		color: "#009b67",
	},
	{
		name: "SC Cambuur",
		value: "sc_cambuur",
		color: "#f7e727",
	},
	{
		name: "FC Den Bosch",
		value: "fc_den_bosch",
		color: "#0073c0",
	},
	{
		name: "TOP Oss",
		value: "top_oss",
		color: "#ce0b10",
	},

	{
		name: "NEC Nijmegen",
		value: "nec_nijmegen",
		color: "#ed1c24",
	},
	{
		name: "FC Volendam",
		value: "fc_volendam",
		color: "#f07200",
	},
	{
		name: "Vitesse",
		value: "vitesse",
		color: "#ffdc00",
	},
	{
		name: "Excelsior",
		value: "excelsior",
		color: "#000000",
	},
];

export default clubs;
