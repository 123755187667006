/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:bb793d06-f655-4447-82d2-3bf2a96c256f",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_cW15rDvoW",
    "aws_user_pools_web_client_id": "3qng4ru7da3kol6pg3jhegthme",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "cedportal-20230922152229-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://df28vabc5vsyd.cloudfront.net",
    "aws_user_files_s3_bucket": "ced-portal-bucket150901-prod",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
