import "@aws-amplify/ui-react/styles.css";
import "@google/model-viewer/dist/model-viewer";
import "highlight.js";
import DeleteIcon from "@mui/icons-material/Delete";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
// import axios from "axios";
import { Storage } from "aws-amplify";

import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FilledInput,
  FormControl,
  InputLabel,
  FormLabel,
  Stack,
  Tooltip,
} from "@mui/material";
import React from "react";

import errors from "../data/errors";

function PreviewDialog({ fileNameKey, setOpen, deleteFile, setFiles }) {
  const [fileUrl, setUrl] = React.useState("");
  const [uploadedToDB, setUploadedToDB] = React.useState(false);
  const [modifiedDate, setModifiedDate] = React.useState("");
  const [error, setError] = React.useState("");

  React.useEffect(() => {
    async function generateConfig() {
      // const credentials = await Auth.currentUserCredentials();
      // console.log("identityId", credentials.identityId);

      const fileUrlRaw = `https://ced-portal-bucket150901-prod.s3.eu-west-1.amazonaws.com/public/${fileNameKey}`;

      setUrl(fileUrlRaw);
      const res = await Storage.getProperties(fileNameKey);

      if (res?.metadata?.db && res?.metadata?.db === "true") {
        setUploadedToDB(true);
      } else {
        setUploadedToDB(false);
      }
      setModifiedDate(res?.lastModified);
      setError(errors[res?.metadata?.error]);
    }

    generateConfig();
  }, [fileNameKey]);

  // React.useEffect(() => {
  //   console.log("inside getRows");
  //   async function getRows() {
  //     try {
  //       const res = await axios.get(`https://70cwsq5kke.execute-api.eu-west-1.amazonaws.com/dev/filerows?fileNameKey=${fileNameKey}`);
  //       console.log(res);
  //       if (res && res.status === 200) {
  //         setFileRows(res.data);
  //       }
  //     } catch (e) {
  //       console.log(e);
  //       setFileRows({ fileRowsDB: 0, fileRowsS3: 0 });
  //     }
  //   }
  //   getRows();
  // }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle>Preview bestandsurl</DialogTitle>
      <DialogContent>
        <Card variant="outlined" sx={{ mt: 4, width: "500px" }}>
          <CardContent>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
              <style>
                {
                  /* CSS */ `
              model-viewer {
                width: 300px;
                height: 300px;
              };
              `
                }
              </style>

              <FormControl variant="filled" size="small" fullWidth>
                <InputLabel>bestand URL</InputLabel>
                <FilledInput type={"text"} value={fileUrl} label="bestand URL" readOnly />
              </FormControl>
              <DeleteIcon
                sx={{
                  "&:hover": {
                    color: "red",
                    backgroundColor: "rgb(0, 0, 0, 0.2)",
                  },
                  transition: "all 0.3s",
                  width: "30px",
                  borderRadius: "5px",
                  padding: "5px",
                  boxSizing: "content-box",
                  height: "30px",
                  cursor: "pointer",
                }}
                onClick={async (event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  await deleteFile(fileNameKey);
                  setFiles((files) => {
                    return files.filter((f) => f.key !== fileNameKey);
                  });
                  handleClose();
                }}
              />
            </Stack>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            {/* <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
              <FormControl variant="filled" size="small" fullWidth>
                <FormLabel>Rijen in database: {fileRows.fileRowsDB} </FormLabel>
              </FormControl>
            </Stack>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
              <FormControl variant="filled" size="small" fullWidth>
                <FormLabel>Rijen in bestand: {fileRows.fileRowsS3} </FormLabel>
              </FormControl>
            </Stack>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
              <FormControl variant="filled" size="small" fullWidth>
                <FormLabel>
                  {" "}
                  Geupload naar database:{" "}
                  {fileRows.fileRowsDB > 0 ? <CheckCircleOutlineIcon color="success" /> : <ErrorOutlineIcon color="error" />}{" "}
                </FormLabel>
              </FormControl>
            </Stack> */}
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
              <FormControl variant="filled" size="small" fullWidth>
                <FormLabel>
                  {" "}
                  Geupload naar database:{" "}
                  {uploadedToDB ? (
                    <CheckCircleOutlineIcon color="success" />
                  ) : (
                    <Tooltip title={error}>
                      <ErrorOutlineIcon color="error" />
                    </Tooltip>
                  )}{" "}
                </FormLabel>
              </FormControl>
            </Stack>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
              <FormControl variant="filled" size="small" fullWidth>
                <FormLabel> Laatst gewijzigd: {modifiedDate.toString().split("(")[0]} </FormLabel>
              </FormControl>
            </Stack>
            {/* {!uploadedToDB && error && error !== "" && (
              <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                <FormControl variant="filled" size="small" fullWidth>
                  <FormLabel> Upload fout: {error} </FormLabel>
                </FormControl>
              </Stack>
            )} */}
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Sluit venster</Button>
      </DialogActions>
    </Dialog>
  );
}

export default PreviewDialog;
